import React from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { useTranslation } from "react-i18next";

export default function ContactsPage() {
    const { t } = useTranslation();

    return(
        <main className="contacts">
            <div className="container">
                <div className="contacts-title">
                    {t('contacts')}
                </div>
                <div className="contacts-items">
                    <div className="contacts-item">
                        <div className="contacts-block">
                            <p className="contacts-block__title">{t('address-text')}</p>
                            <p className="contacts-block__text">{t('address')}</p>
                        </div>
                        <div className="contacts-block">
                            <p className="contacts-block__title">{t('email-text')}</p>
                            <p className="contacts-block__text">{t('email')}</p>
                        </div>
                        <div className="contacts-block">
                            <p className="contacts-block__title">{t('phone-text')}</p>
                            <p className="contacts-block__text">{t('phone')}</p>
                            <p className="contacts-block__text" style={{marginTop: '2px'}}>{t('phone2')}</p>
                        </div>
                    </div>
                        <div className="contacts-map">
                            <div id="map">
                                <YMaps>
                                    <Map defaultState={{ center: [51.171988, 71.420350], zoom: 16.5 }}  width="100%" height="100%">
                                        <Placemark
                                            modules={['geoObject.addon.balloon']}
                                            defaultGeometry={[51.171988, 71.420350]}
                                            properties={{
                                                balloonContentHeader: 'DocStock.kz',
                                                balloonContentBody: `${t('address')}`,
                                                hintContent: 'mnpartners.kz/',
                                            }}
                                        />
                                    </Map>
                                </YMaps>
                            </div>
                        </div>
                </div>
            </div>
        </main> 
    )
}
