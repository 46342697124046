import React from "react";
import { useTranslation } from "react-i18next";
import Item from './item';
export default function AboutUs({contentText}){
    const {t} = useTranslation()

    return (
        <section className="my">
            <img src="./images/poject-bg2.png" className="projects-cubic__right" alt="#" />
            <div className="container">  
                <div className="about-items">
                    <div className="about-photo">
                        <img src="./images/onas1.jpg" className="about-photo__img1" alt="#"/>
                        <img src="./images/onas3.jpg" className="about-photo__img2" alt="#"/>
                        <div className="about-photo__block">
                        </div>
                        <p className="about-photo__year">
                            {t('about-photo-year')}
                        </p>
                    </div>
                    <div className="about-content">
                        <p className="about-title">{t('aboutUs')}</p>
                        <p className="about-suptitle">{t('aboutPage-title')}</p>
                        <div className="about-text">
                            <p>{t('aboutPage-text')}</p>
                        </div>
                        <div className="about-childrens">
                            <Item
                                img = './images/universal-access.svg'
                                title = {t('aboutPage-block1-title')}
                                text = {t('aboutPage-block1-text')}
                            />
                            <Item 
                                img = './images/wallet-fill.svg'
                                title = {t('aboutPage-block2-title')}
                                text = {t('aboutPage-block2-text')}
                            /> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}