const paths = {
    home: '/',
    portfolio: '/portfolio',
    portfolio1: '/portfolio/1',
    portfolio2: '/portfolio/2',
    portfolio3: '/portfolio/3',
    contacts: '/contacts',
    about: '/about',
    all: '*',
};

export default paths;
