import paths from "./paths";
import HomePage from "../Pages/HomePage/HomePage";
import Page404 from "../Pages/Page404/Page404";
import Portfolio from "../Pages/Portfolio/Portfolio";
import ContactsPage from "../Pages/ContactsPage/ContactsPage";
import AboutPage from "../Pages/AboutPage/AboutPage";
import Portfolio1 from "../Pages/Portfolio/Portfolio1";
import Portfolio2 from "../Pages/Portfolio/Portfolio2";
import Portfolio3 from "../Pages/Portfolio/Portfolio3";

const routerProps ={
    home: {
        element: HomePage,
        attributes:{
            path: paths.home,
        }
    },
    about: {
        element: AboutPage,
        attributes:{
            path: paths.about,
        }
    },
    contacts: {
        element: ContactsPage,
        attributes:{
            path: paths.contacts,
        }
    },
    portfolio: {
        element: Portfolio,
        attributes:{
            path: paths.portfolio,
        }
    },
    portfolio1: {
        element: Portfolio1,
        attributes:{
            path: paths.portfolio1,
        }
    },
    portfolio2: {
        element: Portfolio2,
        attributes:{
            path: paths.portfolio2,
        }
    },
    portfolio3: {
        element: Portfolio3,
        attributes:{
            path: paths.portfolio3,
        }
    },
    page_404: {
        element: Page404,
        attributes:{
            path: paths.all,
        }
    }
}
export default routerProps
