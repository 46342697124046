import React from "react";
import { useTranslation } from "react-i18next";
import { range } from 'lodash'
import List from "./list";
export default function Portfolio3() {
    const { t } = useTranslation();

    return(
        <main className="projectPage">
            <section className="projects-wrapper pt-50">
                <div className="container">
                    <div className="projects3">
                        <h1 className="project-list__title mt-20">{t('projectsPage_section4')}</h1>
                        <p className="projects-item__text pt-20">{t('projectsPage_section4_paragraph0')}</p>
                        <p className="projects-item__title1">{t('projectsPage_section4_paragraph3')}</p>

                        <div className="projects-lists">
                            {
                                range(0, 8).map(row => (
                                    <div 
                                        key={row} 
                                        style={{
                                            marginTop: '15px',
                                        }}
                                    >
                                        <p style={{ fontSize: '16px', lineHeight: '26px', fontWeight: '700', marginLeft: '10px'}}> {t(`projectsPage_section4_paragraph1_item${row}_title`)} </p>
                                        <p style={{ fontSize: '16px', lineHeight: '26px'}}> {t(`projectsPage_section4_paragraph1_item${row}_text`)}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <p className="projects-item__text pt-20">{t('projectsPage_section4_paragraph2')}</p>
                    </div>
                </div>
            </section>
        </main>
    )
}