import React from "react";
import { useTranslation } from "react-i18next";
import OnasItem from './onasItem'
import {Link} from "react-router-dom";

export default function Onas() {
    const { t } = useTranslation();
    return(
        <section className="onas">
            <div className="container">
                <div className="onas-title">
                    {t('home-about-tile')}
                </div>
                <div className="onas-items">
                    <div className="onas-item">
                        <p className="onas-item__years">
                            {t('home-about-Years')}
                        </p>
                    </div>
                    <div className="onas-item pl-10">
                        <p className="onas-item__subtitle">
                            {t('home-about-tile1')}
                        </p>
                        <p className="onas-item__text">
                        {t('home-about-text')}
                        </p>
                        <Link to = '/about' className="onas-item__btn">
                            {t('more')}
                        </Link>
                    </div>
                    <div className="onas-item">
                        <div className="onas-blocks">
                            <OnasItem 
                                classes = 'onas-block__top onas-block__left'
                                number = {t('home-about-title2')}
                                text = {t('home-about-text2')}
                            />
                            <OnasItem 
                                classes = 'onas-block__top'
                                number = {t('home-about-title3')}
                                text = {t('home-about-text3')}
                            />
                            <OnasItem 
                                classes = 'onas-block__left'
                                number = {t('home-about-title4')}
                                text = {t('home-about-text4')}
                            />
                            <OnasItem 
                                number = {t('home-about-title5')}
                                text = {t('home-about-text5')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}