import React from "react";
export default function Item({img, title, text}){

    return (
        <div className="about-children">
            <div className="about-children__icon">
                <img src={img} />
            </div>
            <div className="about-children__content">
                <p className="about-children__title">{title}</p>
                <p className="about-children__text">{text}</p>
            </div>
        </div>
    )
}