import React from "react";
import { useTranslation } from "react-i18next";
import Team from './team'

export default function Teams({contentText}){
    const {t} = useTranslation()

    return (
        <section className="team">
            <img src="./images/poject-bg1.png" className="projects-cubic__left" alt="#" />

            <div className="container">
                <h3 className="team-title">
                    {t('ourTeam')}
                </h3>
                <div className="team-items">
                    <Team img='./images/team1.svg' title={t('ourTeam1')} text={t('ourTeam1-text')} />
                    <Team img='./images/team2.svg' title={t('ourTeam2')} text={t('ourTeam2-text')} />
                    <Team img='./images/team3.svg' title={t('ourTeam3')} text={t('ourTeam3-text')} />
                    <Team img='./images/team4.svg' title={t('ourTeam4')} text={t('ourTeam4-text')} />
                    <Team img='./images/team5.svg' title={t('ourTeam5')} text={t('ourTeam5-text')} />
                    <Team img='./images/team6.svg' title={t('ourTeam6')} text={t('ourTeam6-text')} />
                </div>
            </div>
        </section>
    )
}   