import React from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="footer-wrappers">
                        <div className="footer-wrapper">
                            <Link to="/" className="footer-logo">
                                <div className="footer-logo__photo">
                                    <img src="./../images/logo.png" className="header-logo__img" />
                                </div>
                                <p className="footer-logo__text">
                                    <span>Doc</span>
                                    Stock.kz
                                </p>
                            </Link>
                        </div>
                        <div className="footer-wrapper">
                            <ul className="footer-menus">
                                <li><Link to="./../about" className="footer-menu"><img src = "./../images/caret-right.svg" className="footer-menu__icon"/> <span>{t('aboutUs')}</span></Link></li>
                                <li><Link to="./../portfolio" className="footer-menu"><img src = "./../images/caret-right.svg" className="footer-menu__icon"/><span>{t('projects')}</span></Link></li>
                                <li><Link to="./../contacts" className="footer-menu"><img src = "./../images/caret-right.svg" className="footer-menu__icon"/><span>{t('contacts')}</span></Link></li>
                            </ul>
                        </div>
                        <div className="footer-wrapper">
                            <ul className="footer-contacts">
                                <li className="footer-contact"><img src="./../images/geo-alt.svg" className="footer-icon" alt="#"/>{t('address')}</li>
                                <li className="footer-contact"><img src="./../images/email.svg" className="footer-icon" alt="#"/>{t('email')}</li>
                                <li className="footer-contact" style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src="./../images/phone.svg" className="footer-icon" alt="#"/>
                                    <div>
                                        <p>{t('phone')}</p>
                                        <p>{t('phone2')}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p className="footer-bottom__text">
                    {`© DocStock.kz ${moment().format('YYYY')}`}
                </p>
            </div>
        </footer>
    );
}

export default Footer;