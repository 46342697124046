import React from "react";

export default function Activitie({img, title, text}){

    return(
        <div className="activities-item">
            <div className="activities-item__content">
                <figure className="activities-item__photo">
                    <img src={img} className="activities-item__img" alt="" />
                </figure>
                <p className="activities-item__title"> {title} </p>
                <p className="activities-item__text"> {text} </p>
            </div>
        </div>
    )
}
