import React from "react";
import { useTranslation } from "react-i18next";
import TechnologyItem from './technologyItem';

export default function Technology() {
    const { t } = useTranslation();

    return(
        <section className="technology">
            <div className="container">
                <h2 className="technology-title">{t('technologyStack')}</h2>
                <div className="technology-items">
                    <TechnologyItem img='./images/technology/hibernate.svg' />
                    <TechnologyItem img='./images/technology/html.svg' />
                    <TechnologyItem img='./images/technology/java.svg' />
                    <TechnologyItem img='./images/technology/kotlin.svg' />
                    <TechnologyItem img='./images/technology/react.svg' />
                    <TechnologyItem img='./images/technology/vue.svg' />
                    <TechnologyItem img='./images/technology/oracle.svg' />
                    <TechnologyItem img='./images/technology/mongodb.svg' />
                    <TechnologyItem img='./images/technology/microsoft-sql-server.svg' />
                    <TechnologyItem img='./images/technology/css.svg' />
                    <TechnologyItem img='./images/technology/hibernate.svg' />
                    <TechnologyItem img='./images/technology/material-ui.svg' />
                    <TechnologyItem img='./images/technology/redux.svg' />
                    <TechnologyItem img='./images/technology/power-bi.svg' />
                    <TechnologyItem img='./images/technology/postgresql.svg' />
                    <TechnologyItem img='./images/technology/spring.svg' />
                    <TechnologyItem img='./images/technology/semantic-ui.svg' />
                    <TechnologyItem img='./images/technology/javascript.svg' />
                </div>
            </div>
        </section>
    )
}
