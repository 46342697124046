import React from "react";
import { useTranslation } from "react-i18next";
import Title from "../../../components/title";
import Partner from './partner'

export default function Partners() {
    const { t } = useTranslation();

    return (
        <section className="partners">
            <div className="container">
                <Title title={t('partners')} />
                <div className="partners-items">
                    <Partner img="../images/AlmatyEnterprisingCompany.svg" text={t('partners-name1')} />
                    <Partner img="../images/gerb.png" text={t('partners-name2')} />
                    <Partner img="../images/kaf.svg" text={t('partners-name3')} />
                    <Partner img="./images/gerb.png" text={t('partners-name4')} />
                </div>
            </div>
        </section>
    )
}
