import React from "react";
import Insert from "./insert";
import Onas from "./Onas/onas";
import Technology from "./Technology/technology";
import Activities from "./Activities/activities";
import Draft from './Draft/draft'
import Partners from './Partners/partners'


export  default function HomePage() {
    return(
        <>
            <Insert />
            <Onas />
            <Partners />
            <Activities />
            <div className="index1-bg">
                <img src="./../images/mlbanner.jpg" alt="" className="technology-bg" />
                <Technology />
                <Draft />
            </div>
        </>
    )
}
