import React from "react";
export default function Team({img, title, text}){
    return (
        <div className="team-item">
            <div className="team-item__photo">
                <img src={img} alt={title} className="team-item__img" />
            </div>
            <div className="team-item__content">
                <p className="team-item__title">{title}</p>
            </div>
        </div>
    )
}