import React from "react";

export default function Sector({img, text}){

    return (
        <div className="sector-item">
            <img src={img} alt={text} className="sector-item__img" />
            <p className="sector-item__text"> {text} </p>
        </div>
    )
}