import React, {useEffect, useState} from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import routerProps from "../_helpers/routerProps";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import jsonContent from "../_helpers/contentText.json"
import ScrollToTop from './../common/ScrollToTop/scrollToTop'

export default function Root(){
    const mobile_screen_size = 900;
    const mobile_screen = mobile_screen_size+'px';
    const [mobile, setMobile] = useState(false);
    const [pathname, setPathname] = useState("/");
    const contentText = jsonContent

    useEffect(() => (window.onresize = () => {
        if(window.innerWidth >= mobile_screen_size){
            setMobile(false);
        }else{
            setMobile(true);
        }
    }), []);

    return(
        <BrowserRouter>
                <Header contentText={contentText} />
                <ScrollToTop />
                <Switch>
                    <Route {...routerProps.home.attributes}
                           children={<routerProps.home.element setPathname={setPathname}
                            contentText={contentText}/>} exact />

                    <Route {...routerProps.about.attributes} children={<routerProps.about.element
                            setPathname={setPathname} contentText={contentText} />} exact />

                    <Route {...routerProps.contacts.attributes} children={<routerProps.contacts.element
                            setPathname={setPathname} contentText={contentText} />} exact />

                    <Route {...routerProps.portfolio.attributes}
                           children={<routerProps.portfolio.element setPathname={setPathname} 
                           contentText={contentText} />} exact />
                    
                    <Route {...routerProps.portfolio1.attributes} 
                        children={<routerProps.portfolio1.element setPathname={setPathname}/>} exact />

                    <Route {...routerProps.portfolio2.attributes} 
                        children={<routerProps.portfolio2.element setPathname={setPathname}/>} exact />

                    <Route {...routerProps.portfolio3.attributes} 
                        children={<routerProps.portfolio3.element setPathname={setPathname}/>} exact />

                    <Route children={<routerProps.page_404.element setPathname={setPathname}
                            contentText={contentText} />} />
                </Switch>
            <Footer mobile_screen={mobile_screen} setPathname={setPathname} contentText={contentText}/>
        </BrowserRouter>
    )
}
