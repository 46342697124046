import React from "react";
import { useTranslation } from "react-i18next";

export default function Title({title}) {
    const { t } = useTranslation();

    return (
        <div className="partners-title">
            {title}
        </div>
    )
}
