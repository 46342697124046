import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Language from './language/language'
import paths from "./../../_helpers/paths";
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
    const { t } = useTranslation();
    const [menuActive, setMenuActive] = React.useState('')
    let menu = [
        {
            text: 'aboutUs',
            url: paths.about
        },
        {
            text: 'projects',
            url: paths.portfolio
        },
        {
            text: 'contacts',
            url: paths.contacts
        },
    ]
    const [isFixed, serIsFixed] = useState(true);
    let location = useLocation();
    const [current, setCurrent] = useState('/')
    const [isOpen, setIsOpen] = React.useState(false)
    React.useEffect(() => {
        setCurrent(location.pathname)
    }, [location]);

    React.useEffect(() => {
        if (current === '/') {
            serIsFixed(false)
            setMenuActive('')
        } else {
            serIsFixed(true)
        }
    }, [current])

    React.useEffect((e) => {
        window.addEventListener('scroll',function(event){
            if (window.pageYOffset >= window.outerHeight - 300) {
                serIsFixed(true)
            } else {
                serIsFixed(false)
            }
        }); 
    })

    const toogleMenu = (i) => {
        setIsOpen(!isOpen)
        setMenuActive(i)
    }

    React.useEffect(() => {
        if (location.pathname === paths.about) {
            setMenuActive(0)
        } else if (location.pathname === paths.portfolio) {
            setMenuActive(1)
        } else if (location.pathname === paths.contacts) {
            setMenuActive(2)
        }
    })

    return (
        <header className={isFixed || current !== '/' ? 'header header1' : 'header'}>
            <div className="container">
                <div className="header-wrapper">
                    <Link to = '/' className="header-logo">
                        <div className="header-logo__photo">
                            <img src="./../images/logo.png" className="header-logo__img" />
                        </div>
                        <p className="header-logo__text">
                            <span>Doc</span>
                            Stock.kz
                        </p>
                    </Link>
                    <div className="header-content">
                        <ul className={isOpen ? "header-menus active-menu" : "header-menus"}>
                        {
                            menu.map((item, i) => {
                                return <li key={i} onClick = {() => toogleMenu(i)}><Link to={item.url} className={menuActive === i ? 'header-menu header-menu__active' : 'header-menu'}> {t(item.text)} </Link></li>
                            })
                        }

                        </ul>
                        <div className="header-phone">
                            <img src="./../images/telephone.svg" alt="#"/>
                            <div>
                                <span className="header-phone__text">{t('phone')}</span>
                                <span className="header-phone__text">{t('phone2')}</span>
                            </div>
                        </div>
                        <Language />
                    </div>
                    <div className="phone-menu" onClick={toogleMenu}>
                        <MenuIcon style={{ fill: '#1594d1', fontSize: 30}} />
                    </div>
                </div>
            </div>
        </header>
    )
}