import React from "react";
import { useTranslation } from "react-i18next";

export default function Insert({contentText}){
    const { t } = useTranslation();
    return(
        <section className="insert">
            <div className="insert-bg">
                <img src="./images/bg.png" className="insert-bg__img" alt="#" />
                <div className="insert-bg__color"></div>
                <img src="./images/bg-bottom.png" className="insert-bg__img-bottom" alt="#"/>
            </div>
            <div className="insert-content">
                <h1 className="insert-content__title"> DocStock.kz </h1>
                <p className="insert-content__text">{t('insert-h2')}</p>
            </div>
        </section>
    )
}