import React from "react";
import { useTranslation } from "react-i18next";
import Sector from './sector'

export default function Sectors({contentText}){
    const {t} = useTranslation()

    return (
        <section className="sector">
            <div className="container">
                <h2 className="sector-title">{t('Industries')}</h2>
                <p className="sector-text">
                    {t('Industries-text')}
                </p>
                    <div className="sector-items">
                        <Sector img = "./images/sector-icon1.svg" text={t('Industries1')} />
                        <Sector img = "./images/sector-icon2.svg" text={t('Industries2')} />
                        <Sector img = "./images/sector-icon3.svg" text={t('Industries3')} />
                        <Sector img = "./images/sector-icon4.svg" text={t('Industries4')} />
                        <Sector img = "./images/sector-icon5.svg" text={t('Industries5')} />
                        <Sector img = "./images/sector-icon6.svg" text={t('Industries6')} />
                    </div>
            </div>
        </section>
    )
}