import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import List from './list';
import { range } from 'lodash'

export default function Portfolio() {
    const { t } = useTranslation();

    return(
        <main className="projects">
            <section className="projects-wrapper pt-100">
                <img src="./images/poject-bg1.png" className="projects-cubic__left" alt="#" />
                <img src="./images/poject-bg2.png" className="projects-cubic__right" alt="#" />
                <div className="container">
                    <h2 className="projects-title">{t('projects')}</h2>

                    <div className="projects-item mt-20">
                        <p className="projects-item__title"> {t('projectsPage_section1')} </p>
                        <p className="projects-item__text"> {t('projectsPage_section1_paragraph0')} </p>
                        <p className="projects-item__title1">{t('projectsPage_section1_paragraph1')}</p>
                        <div className="projects-lists">
                            <List text={t('projectsPage_section1_paragraph1_item1')} />
                        </div>
                        <Link to = "/portfolio/1" className="projects-btn">
                            {t('more')}
                        </Link>
                    </div>
                </div>
            </section>
            <section className="projects-wrapper projects-wrapper__color">
                <div className="container">
                        <div className="projects-item">
                            <p className="projects-item__title">{t('projectsPage_section2')}</p>
                            <p className="projects-item__text"> {t('projectsPage_section2_paragraph0')} </p>
                            <p className="projects-item__title1">{t('projectsPage_section2_paragraph1')}</p>
                            <div className="projects-lists">
                                {
                                    range(0, 3).map(row1 => (
                                        <List key = {row1} text={t(`projectsPage_section2_paragraph1_item${row1}`)} /> 
                                    ))
                                }
                            </div>
                            <Link to = "/portfolio/2" className="projects-btn">
                                {t('more')}
                            </Link>
                        </div>
                    
                </div>
            </section>
            <section className="projects-wrapper">
                <img src="./images/poject-bg1.png" className="projects-cubic__left" alt="#" />
                <img src="./images/poject-bg2.png" className="projects-cubic__right" alt="#" />
                <div className="container">
                        <div className="projects-item">
                            <p className="projects-item__title">{t('projectsPage_section4')}</p>
                            <p className="projects-item__text">{t('projectsPage_section4_paragraph0')}</p>
                            <p className="projects-item__title1">{t('projectsPage_section4_paragraph3')}</p>
                            <div className="projects-lists">
                                {
                                    range(0, 4).map(row1 => (
                                        <List key = {row1} text={t(`projectsPage_section4_paragraph1_item${row1}_title`)} /> 
                                    ))
                                }
                            </div>
                            <Link to = "/portfolio/3" className="projects-btn">
                                {t('more')}
                            </Link>
                        </div>
                </div>
            </section>
        </main>
    )
}
