import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Title from './../../../components/title'

export default function Draft() {
    const { t } = useTranslation();
    let number = 1;
    const [activeNumber, setActiveNumber] = React.useState(1)

    const onNext = () => {
        if (activeNumber === 1 ) {
            setActiveNumber(1)
        } else {
            setActiveNumber(activeNumber - 1)
        }
    }

    const onPrev = () => {
        setActiveNumber(activeNumber + 1)
        if (activeNumber === 3) {
            setActiveNumber(3)
        } else {
            setActiveNumber(activeNumber + 1)
        }
    }

    return (
        <section className="draft">
            <div className="container">
                <div className="draft-head">
                    <Title title={t('projects')} />

                    <div className="draft-tag">
                        <div className="draft-next" onClick={onNext}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#1594d1" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                        <div className="draft-prev" onClick={onPrev}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#1594d1" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="draft-items">
                    <div className={1 === activeNumber ? "draft-item draft-item__active " : "draft-item draft-item__none"}>
                        <p className="draft-item__title">{t('projectsPage_section1')}</p>
                        <p className="draft-item__text">{t('projectsPage_section1_paragraph0')} </p>
                        <p className="draft-item__title1">{t('projectsPage_section1_paragraph1')}</p>
                        <div className="draft-bottom">
                            <ul className="draft-lists">
                                <li className="draft-list"> <img src="./../images/circle-fill_color1.svg" alt="" className="draft-list__img" /> {t('projectsPage_section1_paragraph1_item0')}</li>
                                <li className="draft-list"> <img src="./../images/circle-fill_color1.svg" alt="" className="draft-list__img"/> {t('projectsPage_section1_paragraph1_item1')}</li>
                            </ul>
                            <div className="draft-btns">
                                <Link to = "/portfolio/1" className="draft-btn">
                                    {t('more')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={2 === activeNumber ? "draft-item draft-item__active" : "draft-item draft-item__none"}>
                        <p className="draft-item__title">{t('projectsPage_section2')}</p>
                        <p className="draft-item__text">{t('projectsPage_section2_paragraph0')}</p>
                        <p className="draft-item__title1">{t('projectsPage_section2_paragraph1')}</p>
                        <div className="draft-bottom">
                            <ul className="draft-lists">
                                <li className="draft-list"> <img src="./../images/circle-fill_color1.svg" alt="" className="draft-list__img" /> {t('projectsPage_section2_paragraph1_item0')}</li>
                                <li className="draft-list"> <img src="./../images/circle-fill_color1.svg" alt="" className="draft-list__img" /> {t('projectsPage_section2_paragraph1_item1')}</li>
                            </ul>
                            <div className="draft-btns">
                                <Link to = "/portfolio/2" className="draft-btn">
                                    {t('more')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={3 === activeNumber ? "draft-item draft-item__active" : "draft-item draft-item__none"}>
                        <p className="draft-item__title">{t('projectsPage_section4')}</p>
                        <p className="draft-item__text">{t('projectsPage_section4_paragraph0')}</p>
                        <p className="draft-item__title1">{t('projectsPage_section3_paragraph1')}</p>
                        <div className="draft-bottom">
                            <ul className="draft-lists">
                                <li className="draft-list"> <img src="./../images/circle-fill_color1.svg" alt="" className="draft-list__img" /> {t('projectsPage_section4_paragraph1_item1_title')}</li>
                                <li className="draft-list"> <img src="./../images/circle-fill_color1.svg" alt="" className="draft-list__img" /> {t('projectsPage_section4_paragraph1_item2_title')}</li>
                                <li className="draft-list"> <img src="./../images/circle-fill_color1.svg" alt="" className="draft-list__img" /> {t('projectsPage_section4_paragraph1_item3_title')}</li>
                                <li className="draft-list"> <img src="./../images/circle-fill_color1.svg" alt="" className="draft-list__img" /> {t('projectsPage_section4_paragraph1_item4_title')}</li>

                            </ul>
                            <div className="draft-btns">
                                <Link to = "/portfolio/3" className="draft-btn">
                                    {t('more')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
