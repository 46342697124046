import React from "react";
import { useTranslation } from "react-i18next";
import { range } from 'lodash'
import List from './list'
export default function Portfolio3() {
    const { t } = useTranslation();

    return(
        <main className="projectPage">
            <section className="projects-wrapper pt-50">
                <div className="container">
                    <h1 className="project-list__title mt-20">{t('projectsPage_section1')}</h1>
                    <p className="projects-item__text mt-20">{t('projectsPage_section1_paragraph0')}</p>
                    
                    <p className="projects-item__title1">{t('projectsPage_section1_paragraph1')}</p>
                    <div className="projects2-items_left">
                        <div className="projects-lists">
                            {
                                range(0, 3).map(row1 => (
                                    <List key={row1} text={t(`projectsPage_section1_paragraph1_item${row1}`)} />
                                ))
                            }
                        </div>
                        <figure className="projects1-block2_wrapper-photo">
                            <img src={'./../images/government.png'} width="180px" className="projects1-block2_wrapper-img" alt="users"/>
                            <figcaption className="projects1-block2_wrapper-text">{t('projectsPage_section1_image0')}</figcaption>
                        </figure>
                    </div>
                    <div className="projects2-items_right">
                        <figure className="projects1-block2_wrapper-photo">
                            <img src={'./../images/terminal.png'} width="180px" className="projects1-block2_wrapper-img" alt="users"/>
                            <figcaption className="projects1-block2_wrapper-text">{t('projectsPage_section1_image1')}</figcaption>
                        </figure>
                        <div>
                            <p className="projects-item__title1 pb-15">{t('projectsPage_section1_paragraph2')}</p>
                            <div className="projects-lists">
                                {
                                    range(0, 4).map(row2 => (
                                        <List key={row2} text={t(`projectsPage_section1_paragraph2_item${row2}`)} /> 
                                    ))
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </main>
    )
}
