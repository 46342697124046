import React from "react";
import { useTranslation } from "react-i18next";
import Sectors from './sectors/sectors'
import Teams from './teams/teams'
import AboutUs from './aboutUs/aboutUs'
export default function AboutPage({contentText}){

    return (
        <main className="about">
            <AboutUs />
            <Sectors />
            <Teams />
        </main>
    )
}