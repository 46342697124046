import React, {useEffect} from "react";
import {Box, Button, Link} from "@mui/material";
import {Page404Styles} from "./Page404Styles";
import {NavLink, useLocation} from "react-router-dom";

export default function Page404({setPathname, contentText}){
    const contents = contentText['Page404'];
    let location = useLocation();
    useEffect(() => {
        setPathname(location.pathname);
    }, [location]);
    return(
        <Page404Styles>
            <Box className="images">
                <div className="content">
                    <img src={("/images/404.svg")}/>
                    <div style={{marginTop: '20px'}}></div>
                    {contents['description1']}
                    <div style={{marginTop: '20px'}}></div>
                    {contents['description2']}
                    <div style={{marginTop: '20px'}}></div>
                    <NavLink to="/">
                        <Button sx={{"&:hover": {
                                backgroundColor: "rgba(255, 255, 255,0.1)",
                                borderRadius: "4px"
                            }, borderRadius: "4px"}}>
                            <span className="go_home_button">{contents['go_home_button']}</span>
                            <img src="/images/go_to_home.svg" style={{padding:"5px"}}/>
                        </Button>
                    </NavLink>
                </div>
            </Box>
        </Page404Styles>
    )
}
