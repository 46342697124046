import React from "react";
import { useTranslation } from "react-i18next";
import { range } from 'lodash'
import List from './list'

export default function Portfolio2() {
    const { t } = useTranslation();

    return(
        <main className="projectPage">
            <section className="projects-wrapper pt-50">
                <div className="container">
                    <h1 className="project-list__title mt-20">{t('projectsPage_section2')}</h1>

                    <p className="projects-item__text pt-20">{t('projectsPage_section2_paragraph0')}</p>
                    <p className="projects-item__title1">{t('projectsPage_section2_paragraph1')}</p>
                        
                    <div className="projects-lists">
                        {
                            range(0, 6).map(row1 => (
                                <List key={row1} text = {t(`projectsPage_section2_paragraph1_item${row1}`)} />
                            ))
                        }
                    </div>
                    <p className="projects-item__title1">{t('projectsPage_section2_paragraph2')}</p>
                        
                        <div className="projects-lists">
                            {
                                range(0, 6).map(row1 => (
                                    <List key = {row1} text={t(`projectsPage_section2_paragraph2_item${row1}`)} />
                                ))
                            }
                        </div>
                </div>
            </section>
        </main>
    )
}
