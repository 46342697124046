import React from "react";
import { useTranslation } from "react-i18next";
import Activitie from './activitie'
export default function Activities(){
    const { t } = useTranslation();

    return(
        <section className="activities">
            <img src="./../images/activitiesBg-1.jpg" className="activities-bg" alt=""/>
            <div className="container">
                <div className="activities-title">
                    {t('activities')}
                </div>
                <div className="activities-grid">
                    <div className="activities-photo">
                        <figure className="activities-photo__img1">
                            <img src="./../images/onas1.jpg" className="activities-photo__img" />
                        </figure>
                        <figure className="activities-photo__img2">
                            <img src="./../images/onas3.jpg" className="activities-photo__img" />
                        </figure>
                    </div>
                    <div className="activities-items">
                        <Activitie 
                            img = './../images/directions-icon1.svg'
                            title = {t('home-activities-type-title1')}
                            text = {t('home-activities-type-text1')}
                        />
                        <Activitie 
                            img = './../images/directions-icon2.svg'
                            title = {t('home-activities-type-title2')}
                            text = {t('home-activities-type-text2')}
                        />
                        <Activitie 
                            img = './../images/directions-icon3.svg'
                            title = {t('home-activities-type-title3')}
                            text = {t('home-activities-type-text3')}
                        />
                        <Activitie 
                            img = './../images/directions-icon4.svg'
                            title = {t('home-activities-type-title4')}
                            text = {t('home-activities-type-text4')}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
